import React from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import cross from "../assets/svgs/templattes/cross.svg"; // Make sure the path is correct

const duration = 300; // Duration for both the off-canvas and backdrop transition

// Default styles for the off-canvas component
const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out`,
  transform: "translateX(100%)",
  position: "fixed",
  top: 0,
  right: 0,
  height: "100%",
  width: "40%",
  zIndex: 1000,
  backgroundColor: "#fff",
  boxShadow: "-2px 0 5px rgba(0,0,0,0.2)",
};

// Transition styles for entering and exiting states
const transitionStyles = {
  entering: { transform: "translateX(0%)" },
  entered: { transform: "translateX(0%)" },
  exiting: { transform: "translateX(100%)" },
  exited: { transform: "translateX(100%)" },
};

const OffCanvas = ({ isOpen, onClose, children }) => {
  return (
    <>
      {/* Backdrop */}
      <Transition in={isOpen} timeout={duration} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-${duration} `}
            style={{
              opacity: state === "entered" || state === "entering" ? 1 : 0,
              pointerEvents: state === "entered" ? "auto" : "none", // Disable click events when off-canvas is closed
            }}
            onClick={onClose} // Close the off-canvas when clicking on the backdrop
          />
        )}
      </Transition>

      {/* Off-canvas content */}
      <Transition in={isOpen} timeout={duration} mountOnEnter unmountOnExit>
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state], // Apply the correct style based on the current transition state
            }}
          >
            <div className="relative h-full w-full p-6 overflow-y-auto overflow-x-hidden">
              {/* Close button (using the SVG icon) */}
              <button className="absolute top-5 right-9" onClick={onClose}>
                <img
                  src={cross}
                  alt="Close"
                  className="w-6 h-6 cursor-pointer"
                />
              </button>

              {/* Content inside the off-canvas */}
              <div className="px-[3.5em] py-[6em]">{children}</div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
};

// Prop types for better validation
OffCanvas.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default OffCanvas;
